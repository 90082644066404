* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: Roboto;
    line-height: 1;
}

ol,
ul {
    list-style: none;
    padding-inline-start: 0px;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

#navbar {
    overflow: hidden;
    transition: 0.4s;
    position: fixed;
    width: 100%;
    top: 0;
    display: flex;
    align-items: center;
    margin-bottom: 250px;
    z-index: 99;
}

#navbar img {
    max-width: 175px;
    cursor: pointer;
}

@keyframes expanded-animate {
    0% {
        transform: scale(0.75);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

#navbar img.expanded {
    transform: scale(1);
    animation: expanded-animate 1s ease;
}

@keyframes minimized-animate {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.7);
    }
    100% {
        transform: scale(0.75);
    }
}

#navbar img.minimized {
    transform: scale(0.75);
    animation: minimized-animate 1s ease;
}

#navbar.expanded {
    font-size: 35px;
    height: 200px;
    text-align: "center";
    transition: all 0.4s ease-out;
}

#navbar.minimized {
    font-size: 25px;
    height: 125px;
    transition: all 0.4s ease-out;
}

@keyframes minimized-animate-nav {
    0% {
        height: 200px;
    }
    100% {
        height: 100px;
    }
}

#navbar a {
    float: left;
    color: black;
    text-align: center;
    padding: 12px;
    text-decoration: none;
    font-size: 18px;
    line-height: 25px;
    border-radius: 4px;
}

#navbar #logo {
    font-size: 35px;
    font-weight: bold;
    transition: 0.4s;
}

#navbar a:hover {
    background-color: #ddd;
    color: black;
}

#navbar a.active {
    background-color: dodgerblue;
    color: white;
}

#navbar-right {
    float: right;
}

@media screen and (max-width: 580px) {
    #navbar {
        padding: 20px 10px !important;
    }
    #navbar a {
        float: none;
        display: block;
        text-align: left;
    }
    #navbar-right {
        float: none;
    }
}

.projects {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
    align-items: center;
    justify-items: center;
}

.projects > ul > li {
    padding: 1rem;
}

h1 {
    font-size: 6rem;
    padding-left: 5%;
    padding-top: 5%;
}

.sheet {
    margin: 0rem 2rem;
    border-radius: 0.5rem;
    background-color: #f5f5f5;
    padding-bottom: 5rem;
    margin-bottom: 2rem;
    /* min-width: 300px; */
}

.sheet h1 {
    width: 100%;
    text-align: center;
    padding: 0px auto;
    font-weight: 300;
    font-size: 4.5rem;
}

.projects .project-card {
    display: flex;
    align-content: center;
    justify-content: center;
    cursor: pointer;
    max-height: 200px;
    min-height: 200px;
    margin: 1rem;
    width: 90%;
}

.projects .project-card .project-card-body {
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    color: #ffffff;
    width: 100%;
    gap: 1rem;
    padding-bottom: 16px;
}

.projects .project-card .project-card-body span {
    font-weight: 200;
    font-size: 40px;
}

.projects .project-card .project-card-body img {
    width: 5rem;
}

.projects .project-card .project-card-body .jc-img {
    width: 15rem;
}

@media screen and (max-width: 580px) {
    .projects {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        align-items: center;
        justify-items: center;
    }
    
    .sheet {
        margin: 0rem 1rem;
        padding-bottom: 2.5rem;
        margin-bottom: 2.5rem;
    }
    
    .sheet h1 {
        font-size: 3rem;
    }

    .projects .project-card {
        max-height: 200px;
        min-height: 100px;
        margin: 1rem;
        padding-top: 0px;
        padding-bottom: 0px
    }

    .projects .project-card .project-card-body span {
        font-weight: 200;
        font-size: 1.5rem;
    }

    .projects .project-card .project-card-body img {
        width: 3rem;
    }

    .projects .project-card .project-card-body .jc-img {
        width: 7.5rem;
    }
}

.MuiCardContent-root {
    padding: 0px;
}